<template>
  <div class="slider-block">
    <img :src="sliderUrl.url" class="slider-block__img" loading="lazy" v-if="sliderUrl.img" />
    <video class="slider-block__video" controls preload="metadata" :poster="sliderUrl.poster" v-else>
      <source :src="sliderUrl.url">
    </video>
    <a href="http://na-sml-bithday.ru/" class="radio-block__button" v-if="sliderUrl.link">Подробнее</a>
  </div>
</template>

<script>
export default {
  props: ["sliderUrl"],
};
</script>

<style lang="scss">
.slider-block {
  //max-width: 1280px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: fit-content;
  padding: 12px;

  @media (max-width: 1500px) {
    max-width: 100%;
  }

  &__img {
    height: 450px;
    object-fit: contain;
    @media (max-width: 600px) {
      height: auto;
    }
  }

  &__video{
    max-width: 100%;
    height: 450px;
    object-fit: contain;
  }
}
</style>