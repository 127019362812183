<template>
    <section class="socials">
        <div class="header-title_sub">
            <h2>Читайте нас в социальных сетях</h2>
        </div>
        <div class="socials__body">
            <social 
                :icon="social.icon"
                :title="social.title"
                :text="social.text"
                :url="social.url"
                v-for="(social, index) in socials" :key="index" 
            />
        </div>
    </section>
</template>

<script>
import Social from "./Social.vue";
export default {
  data(){
    return {
        socials: [
            {
                icon: require("../../assets/socials-logo/litres-new.png"), 
                title: 'Литрес', 
                text: 'Покупай литературу сообщества Анонимные Наркоманы в электронном формате на сайте',
                url: 'https://www.litres.ru/author/anonimnye-narkomany-32034193/'
            },
            {
                icon: require("../../assets/socials-logo/youtube.svg"), 
                title: 'Youtube канал', 
                text: 'Смотри личные истории и интервью на нашем канале в YouTube',
                url: 'https://www.youtube.com/channel/UCMD2eYn1t6Tz6eb4ShQMxYw'
            },
            {
                icon: require("../../assets/socials-logo/vk.svg"), 
                title: 'ВКонтакте', 
                text: 'Подписывайся на нас в ВКонтакте',
                url: 'https://vk.com/na_russia_official'
            },
            {
                icon: require("../../assets/socials-logo/zen.svg"), 
                title: 'Yandex Zen', 
                text: 'Читай наши истории на Яндекс.Дзен',
                url: 'https://dzen.ru/narcotics_anonymous'
            },
            {
                icon: require("../../assets/socials-logo/podcastna.svg"), 
                title: 'Подкасты АН', 
                text: 'Как жить без наркотиков. Истории реальных людей, которые прекратили употреблять',
                url: 'https://podcastna.mave.digital/'
            },
        ]
    }
  },
  components: {
    Social
  },
};
</script>

<style lang="sass">
    .socials
        margin-bottom: 80px

        &__body
            display: grid
            grid-template-columns: repeat(auto-fill, minmax(#{"min(410px, 100%)"}, 1fr));
            gap: 40px 20px
</style>