<template>
  <div class="home">
    <h1 class="header-title">
      Официальный сайт Смоленского сообщества Анонимные наркоманы(АН).
    </h1>
    <slider-main />
    <info-block />
    <socials />
  </div>
</template>
<script>
// @ is an alias to /src
import InfoBlock from "@/components/Home/InfoBlock";
import SliderMain from "@/components/Home/SliderMain";
import Socials from "@/components/Home/Socials";
export default {
  name: "Home",
  created() {
    document.title = "Главная";
  },
  components: {
    SliderMain,
    InfoBlock,
    Socials
  },
};
</script>

<style lang="scss">
  .home{
    // max-width: 1280px;
    // @media screen and (max-width: 1610px) {
    //   max-width: 1000px;
    // }
    // @media screen and (max-width: 1330px) {
    //   max-width: 600px;
    // }
    // @media screen and (max-width: 1014px) {
    //   max-width: inherit;
    // }
  }
</style>
