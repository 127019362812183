<template>
    <a :href="url" class="social-home" target="_blank" rel="nofollow">
        <div class="social-home__icon">
            <img :src="icon" :alt="title">
        </div>
        <div class="social-home__info">
            <div class="social-home__title under-line-animate">
                {{ title }}
            </div>
            <div class="social-home__text">
                {{ text }}
            </div>
        </div>
    </a>
</template>

<script>
export default {
  components: {

  },
  props: ['icon', 'title', 'text', 'url']
};
</script>

<style lang="sass">
    .social-home
        display: flex
        // justify-content: space-between
        align-items: center
        gap: 20px

        @media screen and (max-width: 600px) 
            flex-flow: column
            align-items: flex-start

        text-decoration: none

        &__icon
            width: 100px
            height: 100px
            flex-shrink: 0
            transition: scale 0.5s linear

            img
                width: 100%
                height: 100%

        &__info
            display: flex
            flex-flow: column

        &__title
            margin: 0
            margin-bottom: 12px
            width: fit-content

            font-size: 36px
            color: #1c7fe2

        &__text
            font-size: 18px
            color: #000


        &:hover
            .social__icon
                scale: 1.05
            .under-line-animate
                &::before 
                    visibility: visible
                    transform: scaleX(1)
            
</style>