<template>
  <section class="slider-body">
    <div class="slider">
      <div class="slider__button slider__button-prev is-prev" ref="prev">
        <i class="fas fa-chevron-left"></i>
      </div>
      <div class="slider__container swiper" ref="sliderContainer">
        <div class="slider__wrapper swiper-wrapper">
          <div class="slider__slide swiper-slide" v-for="(item, index) in list" :key="index">
            <slider-main-img :sliderUrl="item" />
          </div>
        </div>
      </div>
      <div class="slider__button slider__button-next is-next" ref="next">
        <i class="fas fa-chevron-right"></i>
      </div>
    </div>
  </section>
</template>

<script>
import SliderMainImg from "./SliderMainImg.vue";
import Swiper, { Navigation, Pagination, Autoplay, Zoom } from "swiper";
Swiper.use([Navigation, Pagination, Autoplay, Zoom]);
// Import Swiper styles
import 'swiper/swiper.scss';

export default {
  data() {
    return {
      list: [
        // { url: require("../../assets/video/video-bzd.mp4"), img: false, link: false, poster: require("../../assets/poster/video-poster-1.png") },
        { url: require("../../assets/video/na-sml.mp4"), img: false, link: false, poster: require("../../assets/poster/poster-video.png") },
        { url: require("../../assets/poster/poster3.webp"), img: true, link: false },
        { url: require("../../assets/poster/poster1.webp"), img: true, link: false },
        { url: require("../../assets/poster/poster2.webp"), img: true, link: false },
      ],
    };
  },
  components: {
    SliderMainImg,
  },
  mounted() {
    setTimeout(() => {
      new Swiper(this.$refs.sliderContainer, {
        //wrapperClass: "slider__wrapper",
        //slideClass: "slider__slide",
        slidesPerView: 1,
        autoHeight: true,
        zoom: true,
        navigation: {
          prevEl: this.$refs.prev,
          nextEl: this.$refs.next,
        },
        // pagination: {
        //   el: ".slider__pagination",
        // },
        speed: 1000,
        autoplay: true,
        reverseDirection: true,
        breakpoints: {
          320: {
            spaceBetween: 0,
            allowTouchMove: true
          },
          600: {
            spaceBetween: 20,
            allowTouchMove: false,
          },
        },
      });
    }, 300);
  },
};
</script>

<style lang="scss">
section {
  overflow: hidden;
}

.slider {
  max-width: 100%;
  background: rgba(28, 127, 226, 0.1);
  position: relative;
  margin-bottom: 12px;

  &-body {
    container-type: inline-size;
    // max-width: 1276px;
    // width: 100%;
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media screen and (max-width: 600px) {
      height: auto;
    }
  }

  &__slide{
    width: 100%;
    height: 100%;
    //max-width: 1280px;
  }

  &__controls {
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: space-between;
    width: 100%;
    transform: translateY(-50%);

    @media (max-width: 600px) {
      top: 50%;
      width: 100%;
    }
  }

  &__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 30px;
    width: 30px;
    color: #000;
    font-size: 30px;
    cursor: pointer;

    &:hover {
      color: #ccc;
    }

    &-prev{
        left: 10px;
        z-index: 2;
    }

    &-next {
      text-align: right;
      right: 10px;
      z-index: 2;
    }
  }
}
</style>